import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import AddBookmark from './components/AddBookmark/AddBookmark';
import BookmarkList from './components/BookmarkList/BookmarkList';
import BookmarksContext from './BookmarksContext';
import Nav from './components/Nav/Nav';
import EditBookmarkForm from './components/EditBookmark/EditBookmarkForm'
import config from './config';
import './App.css';

class App extends Component {
  state = {
    bookmarks: [],
    error: null,
  };

  setBookmarks = bookmarks => {
    this.setState({
      bookmarks,
      error: null,
    })
  }

  addBookmark = bookmark => {
    this.setState({
      bookmarks: [ ...this.state.bookmarks, bookmark ],
    })
  }

  deleteBookmark = bookmarkId => {
    const newBookmarks = this.state.bookmarks.filter(bm =>
    bm.id !== bookmarkId
  )
    this.setState({
      bookmarks: newBookmarks
    })
  }
  componentDidMount() {
    fetch(config.API_ENDPOINT, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${config.API_KEY}`,
      }
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(error => Promise.reject(error))
        }
        return res.json()
      })
      .then(resJson => this.setBookmarks(resJson))
      .catch(error => this.setState({ error }))
  }

  updateBookmark = updatedBookmark => {
    const newBookmarks = this.state.bookmarks.map(bm => bm.id == updatedBookmark.id ? updatedBookmark : bm)
    this.setState(
      {bookmarks: newBookmarks}
    )
  }

  render() {
    const contextValue = {
      bookmarks: this.state.bookmarks,
      addBookmark: this.addBookmark,
      deleteBookmarkRequest: this.deleteBookmark,
      updateBookmark: this.updateBookmark
    };

    return (
      <main className='App'>
        <h1>Bookmarks!</h1>
        <BookmarksContext.Provider value={contextValue}>
        <Nav />
        <div className='content' aria-live='polite'>
          <Route exact path='/' component={BookmarkList}/>
          <Route path='/add-bookmark' component={AddBookmark}/>
          <Route path='/edit/:bookmarkId' component={EditBookmarkForm}/>
        </div>
        </BookmarksContext.Provider>
      </main>
    );
  }
}

export default App;
