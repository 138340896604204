import React from 'react';
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import Rating from '../Rating/Rating';
import config from '../../config';
import { BrowserRouter } from 'react-router-dom';
import BookmarksContext from '../../BookmarksContext';
import './BookmarkItem.css';

export default function BookmarkItem(props) {

function deleteBookmarkRequest(bookmarkId, cb) {
  fetch(config.API_ENDPOINT + `/${bookmarkId}`, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
      'Authorization': `Bearer ${config.API_KEY}`
    }
  })
  .then(res => {
    if(!res.ok) {
      return res.json().then(error => Promise.reject(error))
    }
  })
  .then(() => {
    cb(bookmarkId)
  })
  .catch(error => {
    console.log(error)
  })
}

  return (
    <BrowserRouter>
    <BookmarksContext.Consumer>
      {(context) => (
        <li className='BookmarkItem'>
          <div className='BookmarkItem__row'>
            <h3 className='BookmarkItem__title'>
              <a
                href={props.url}
                target='_blank'
                rel='noopener noreferrer'>
                {props.title}
              </a>
            </h3>
            <Rating value={props.rating} />
          </div>
          <p className='BookmarkItem__description'>
            {props.description}
          </p>
          <div className='BookmarkItem__buttons'>
          <Link to={`/edit/${props.id}`}>Edit Bookmark</Link>

            <button className='BookmarkItem__delete'
              onClick={() => {
                deleteBookmarkRequest(
                  props.id,
                  context.deleteBookmarkRequest
                )
              }}
            >
              Delete
            </button>
          </div>
        </li>
    )}
    </BookmarksContext.Consumer>
    </BrowserRouter>
  )
}

BookmarkItem.defaultProps = {
  rating: 1,
  description: '',
}

BookmarkItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  rating: PropTypes.number,
  description: PropTypes.string
}
