import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BookmarksContext from '../../BookmarksContext';
import BookmarkItem from '../BookmarkItem/BookmarkItem';
import './BookmarkList.css'

export default class BookmarkList extends Component {

  render() {
    // const { bookmarks } = this.context
    return (
      <BookmarksContext.Consumer>
        {({bookmarks})=> {
          return (
            <section className='BookmarkList'>
              <h2>Your bookmarks</h2>
              <ul className='BookmarkList__list' aria-live='polite'>
                {bookmarks.map(bookmark =>
                  <BookmarkItem
                    key={bookmark.id}
                    {...bookmark}
                  />
                )}
              </ul>
            </section>
          )
          }}
      </BookmarksContext.Consumer>
    );
  }
}

BookmarkList.propTypes = {
  bookmarks: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    rating: PropTypes.number,
    description: PropTypes.string
  }))
}