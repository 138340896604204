import React, { Component } from 'react';
import propTypes from 'prop-types'
import config from '../../config'
import BookmarksContext from '../../BookmarksContext';
import './EditBookmarkForm.css';

export default class EditBookmarkForm extends Component {
    static contextType = BookmarksContext;
    static propTypes = {
        match: propTypes.shape({
            params: propTypes.object
        }),
        history: propTypes.shape({
            push: propTypes.func,
        }).isRequired,
    }

    state = {
        error: null,
        id: this.props.match.params.bookmarkId,
        title: '',
        url: '',
        description: '',
        rating: 1,
    }

    componentDidMount() {
        const {bookmarkId} = this.props.match.params
        fetch(config.API_ENDPOINT + `/${bookmarkId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${config.API_KEY}`
            }
        })
        .then(res => {
            if (!res.ok) {
                return res.json().then(error => Promise.reject(error))
            }
            return res.json()
        })
        .then(resJson => {
            this.setState({
                title: resJson.title,
                url: resJson.url,
                description: resJson.description,
                rating: resJson.rating
            })
        })
        .catch(error => {
            console.log(error)
            this.setState({error})
        })
    }

    updateTitle = e => {this.setState({title: e.target.value})}
    updateUrl = e => {this.setState({url: e.target.value})}
    updateDescription = e => {this.setState({description: e.target.value})}
    updateRating = e => {this.setState({rating: e.target.value})}

    handleSubmit = e => {
        e.preventDefault()
        const {bookmarkId} = this.props.match.params
        const {id, title, url, description, rating} = this.state
        const newBookmark = {id, title, url, description, rating}
        console.log(newBookmark)
        fetch(config.API_ENDPOINT + `/${bookmarkId}`, {
            method: 'PATCH',
            body: JSON.stringify(newBookmark),
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${config.API_KEY}`
            }
        })
        .then(res => {
            if(!res.ok) {
                return res.json().then(error => Promise.reject(error))
            }
        })
        .then(() => {
            console.log(newBookmark)
            this.resetFields(newBookmark)
            this.context.updateBookmark(newBookmark)
            this.props.history.push('/')
        })
        .catch(error => {
            console.log(error)
            this.setState({error})
        })
    }

    resetFields = (newFields) => {
        console.log(newFields)
        this.setState({
            id: newFields.id || '',
            title: newFields.title || '',
            url: newFields.url || '',
            description: newFields.description || '',
            rating: newFields.rating || '',
        })
    }

    render() {
        const {title, url, description, rating, error} = this.state
        return (
            <section className='EditBookmarkForm'>
                <h2>Edit Bookmark</h2>
                <form
                    className="EditBookmark_form"
                    onSubmit={this.handleSubmit}>
                    <div className="EditBookmark_error" role="alert">{error && <p>{error.message}</p>}</div>
                    <label htmlFor="title">Title
                        <span className="required">(required)</span>
                    </label>
                    <input type="text" id="title" name="title" aria-label="title of bookmark" 
                        value={title}
                        onChange={this.updateTitle}/>
                    <label htmlFor="url">Url
                        <span className="required">(required)</span>
                    </label>
                    <input type="text" inputMode="url" id="url" name="url" aria-label="url for bookmarked page" 
                        value={url}
                        onChange={this.updateUrl}/>
                    <label htmlFor="description" id="description" name="description">Description
                    </label>
                    <input type="text" spellCheck="true" id="description" name="description" aria-label="description of bookmark" 
                        value={description}
                        onChange={this.updateDescription}/>
                    <label htmlFor="rating">Rating
                        <span className="required">(required</span>
                    </label>
                    <input type="number" id="rating" name="rating" aria-label="rating of bookmark" min="0" max="5" 
                        value={rating}
                        onChange={this.updateRating}/>
                    <section className="form-buttons">
                        <button type="submit">Save</button>
                        <button onClick={() => this.props.history.push('/')}>Cancel</button>
                    </section>
                </form>
            </section>
        )
    }
}
